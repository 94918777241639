import { type BadgeVariant } from "@/components/ui/badge";

export const postevent = {
  ERROR_HANDLER_TOP_LEVEL: "error-handler-top-level",
  ERROR_HANDLER_PRIVATE: "error-handler-private",
  ERROR_HANDLER_PUBLIC: "error-handler-public",
  TERMS_ACCEPTED: "terms-accepted",
  TERMS_ACCEPTED_FAILED: "terms-accepted-failed",
  CLERK_USER_CREATED: "clerk-user-created",
  CLERK_USER_CREATED_FAILED: "clerk-user-creation-failed",
  CLERK_EMAIL_VALIDATED: "clerk-email-validated",
  CLERK_EMAIL_VALIDATION_FAILED: "clerk-email-validation-failed",
  USER_CREATED: "user-created",
  USER_CREATED_FAILED: "user-creation-failed",
  USER_UPDATED: "update-user",
  USER_UPDATE_FAILED: "update-user-failed",
  USER_UPDATE_UNAUTHORIZED: "update-user-unauthorized",
  USER_DELETED: "user-deleted",
  USER_DELETE_FAILED: "user-delete-failed",
  USER_DELETE_UNAUTHORIZED: "user-delete-unauthorized",
  INVITE_USER: "invite-user",
  INVITE_USER_FAILED: "invite-user-failed",
  INVITE_USER_NOT_FOUND: "invite-user-not-found",
  INVITE_USER_UNAUTHORIZED: "invite-user-unauthorized",
  INVITE_USER_EMAIL_SENT: "invite-user-email-sent",
  INVITE_USER_EMAIL_FAILED: "invite-user-email-failed",
  INVITE_DELETED: "invite-deleted",
  INVITE_DELETE_FAILED: "invite-delete-failed",
  INVITE_DELETED_UNAUTHORIZED: "invite-deleted-unauthorized",
  IDENTIFY_USER: "identify-user",
  IDENTIFY_GUEST_USER: "identify-guest-user",
  GUEST_USERNAME_UPDATED: "guest-username-updated",
  GUEST_USERNAME_UPDATE_FAILED: "guest-username-update-failed",
  PROJECT_CREATED: "project-created",
  PROJECT_CREATION_FAILED: "project-creation-failed",
  PROJECT_UPDATE: "project-update",
  PROJECT_UPDATE_FAILED: "project-update-failed",
  PROJECT_DELETED: "project-deleted",
  PROJECT_DELETE: "project-delete",
  PROJECT_DELETE_FAILED: "project-delete-failed",
  PROJECT_DELETE_UNAUTHORIZED: "project-delete-unauthorized",
  PROJECT_UPDATE_DESIGN_TOOL: "project-update-design-tool",
  PROJECT_UPDATE_DESIGN_TOOL_FAILED: "project-update-design-tool-failed",
  PROJECT_UPDATE_SITE_DETAILS: "project-update-site-details",
  PROJECT_UPDATE_SITE_DETAILS_FAILED: "project-update-site-details-failed",
  PROJECT_UPDATE_STRUCTURAL_ENG_REQUIRED: "project-update-structural-eng-required",
  PROJECT_UPDATE_STRUCTURAL_ENG_REQUIRED_FAILED: "project-update-structural-eng-required-failed",
  PROJECT_UPDATE_STRUCTURAL_ENG_NOT_REQUIRED: "project-update-structural-eng-not-required",
  PROJECT_UPDATE_STRUCTURAL_ENG_NOT_REQUIRED_FAILED:
    "project-update-structural-eng-not-required-failed",
  PROJECT_UPDATE_ELECTRICAL_ENG_REQUIRED: "project-update-electrical-eng-required",
  PROJECT_UPDATE_ELECTRICAL_ENG_REQUIRED_FAILED: "project-update-electrical-eng-required-failed",
  PROJECT_UPDATE_ELECTRICAL_ENG_NOT_REQUIRED: "project-update-electrical-eng-not-required",
  PROJECT_UPDATE_ELECTRICAL_ENG_NOT_REQUIRED_FAILED:
    "project-update-electrical-eng-not-required-failed",
  ORGANIZATION_CREATED: "organization-created",
  ORGANIZATION_CREATION_FAILED: "organization-creation-failed",
  ORGANIZATION_UPDATED: "organization-updated",
  ORGANIZATION_UPDATE_FAILED: "organization-update-failed",
  ORGANIZATION_DELETE: "organization-delete",
  ORGANIZATION_DELETE_FAILED: "organization-delete-failed",
  ORGANIZATION_DELETE_UNAUTHORIZED: "organization-delete-unauthorized",
  RESET_PASSWORD: "reset-password",
  RESET_PASSWORD_FAILED: "reset-password-failed",
  RESET_PASSWORD_EMAIL_SENT: "reset-password-email-sent",
  RESET_PASSWORD_EMAIL_FAILED: "reset-password-email-failed",
  RESET_PASSWORD_EMAIL_NOT_FOUND: "reset-password-email-not-found",
  PROJECT_PHOTOS_UPLOADED: "project-photos-uploaded",
  PROJECT_PHOTOS_UPLOAD_FAILED: "project-photos-upload-failed",
  PROJECT_SUBMITTED_FOR_PLANS: "project-submitted-for-plans",
  PROJECT_SUBMIT_FOR_PLANS_FAILED: "project-submit-for-plans-failed",
  PROJECT_SUBMITTED_EMAIL_SENT: "project-submitted-for-plans-email-sent",
  PROJECT_SUBMITTED_EMAIL_FAILED: "project-submitted-for-plans-email-failed",
  PROJECT_SUBMITTED_EMAIL_TO_CUSTOMER_SENT: "project-submitted-for-plans-email-to-customer-sent",
  PROJECT_SUBMITTED_EMAIL_TO_CUSTOMER_FAILED:
    "project-submitted-for-plans-email-to-customer-failed",
  PROJECT_CHANGE_REQUEST_SUBMITTED: "project-change-request-submitted",
  PROJECT_CHANGE_REQUEST_SUBMIT_FAILED: "project-change-request-submit-failed",
  PROJECT_CHANGE_REQUEST_EMAIL_SENT: "project-change-request-email-sent",
  PROJECT_CHANGE_REQUEST_EMAIL_FAILED: "project-change-request-email-failed",
  PROJECT_VENDOR_CHANGE_REQUEST_SUBMITTED: "project-vendor-change-request-submitted",
  PROJECT_VENDOR_CHANGE_REQUEST_SUBMIT_FAILED: "project-vendor-change-request-submit-failed",
  PROJECT_VENDOR_CHANGE_REQUEST_SUBMIT_EMAIL: "project-vendor-change-request-submit-email",
  PROJECT_VENDOR_CHANGE_REQUEST_SUBMIT_EMAIL_FAILED:
    "project-vendor-change-request-submit-email-failed",
  PROJECT_VENDOR_PLANS_SUBMITTED: "project-vendor-plans-submitted",
  PROJECT_VENDOR_PLANS_SUBMIT_FAILED: "project-vendor-plans-submit-failed",
  PROJECT_VENDOR_PLANS_SUBMIT_EMAIL: "project-vendor-plans-submit-email",
  PROJECT_VENDOR_PLANS_SUBMIT_EMAIL_FAILED: "project-vendor-plans-submit-email-failed",
  PROJECT_PLANS_APPROVED: "project-plans-approved",
  PROJECT_PLANS_APPROVED_FAILED: "project-plans-approved-failed",
  PROJECT_PLANS_APPROVED_EMAIL_SENT: "project-plans-approved-email-sent",
  PROJECT_PLANS_APPROVED_EMAIL_FAILED: "project-plans-approved-email-failed",
  PROJECT_PLANS_APPROVED_EMAIL_TO_CUSTOMER_SENT: "project-plans-approved-email-to-customer-sent",
  PROJECT_PLANS_APPROVED_EMAIL_TO_CUSTOMER_FAILED:
    "project-plans-approved-email-to-customer-failed",
  PROJECT_MARKED_COMPLETE: "project-marked-complete",
  PROJECT_MARKED_COMPLETE_FAILED: "project-marked-complete-failed",
  PROJECT_MARKED_COMPLETE_EMAIL_SENT: "project-marked-complete-email-sent",
  PROJECT_MARKED_COMPLETE_EMAIL_FAILED: "project-marked-complete-email-failed",
  POSTMARK_EMAIL_SENT: "postmark-email-sent",
  POSTMARK_EMAIL_FAILED: "postmark-email-failed",
  PROJECT_SECTION_STATUS_UPDATED: "project-section-status-updated",
  PROJECT_SECTION_STATUS_UPDATE_FAILED: "project-section-status-update-failed",
  MESSAGE_CREATED: "message-created",
  MESSAGE_CREATION_FAILED: "message-creation-failed",
  MESSAGE_UPDATE: "message-update",
  MESSAGE_UPDATE_FAILED: "message-update-failed",
  MESSAGE_DELETE: "message-delete",
  MESSAGE_DELETE_FAILED: "message-delete-failed",
  ORGANIZATION_MISSING_VENDOR_EMAILS: "organization-missing-vendor-emails",
  EXPORT_SYSTEM_DATA: "export-system-data",
  EXPORT_SYSTEM_DATA_FAILED: "export-system-data-failed",
  EXPORT_SYSTEM_DATA_UNAUTHORIZED: "export-system-data-unauthorized",
  CONFIG_TOOL_ATTACH_TO_PROJECT: "config-tool-attach-to-project",
  CONFIG_TOOL_ATTACH_TO_PROJECT_FAILED: "config-tool-attach-to-project-failed",
  CONFIG_TOOL_ATTACH_TO_PROJECT_UNAUTHORIZED: "config-tool-attach-to-project-unauthorized",
  BOM_CREATED: "bom-created",
  BOM_CREATION_FAILED: "bom-creation-failed",
  BOM_UPDATED: "bom-updated",
  BOM_UPDATE_FAILED: "bom-update-failed",

  PZSE_API_QUERY: "pzse-api-query",
  PZSE_API_QUERY_FAILED: "pzse-api-query-failed",
  PZSE_API_QUERY_UNAUTHORIZED: "pzse-api-query-unauthorized",

  TOPSPEED_API_QUERY: "topspeed-api-query",
  TOPSPEED_API_QUERY_FAILED: "topspeed-api-query-failed",
  TOPSPEED_API_QUERY_UNAUTHORIZED: "topspeed-api-query-unauthorized",

  PART_CREATED: "part-created",
  PART_CREATION_FAILED: "part-creation-failed",
  PART_CREATED_UNAUTHORIZED: "part-created-unauthorized",

  PART_UPDATE: "part-update",
  PART_UPDATE_FAILED: "part-update-failed",
  PART_UPDATE_UNAUTHORIZED: "part-update-unauthorized",

  PART_DELETED: "part-delete",
  PART_DELETED_FAILED: "part-delete-failed",
  PART_DELETED_UNAUTHORIZED: "part-delete-unauthorized",

  // Events used by Scanifly server processing
  SCANIFLY_MISSING_CONFIGURATION: "scanifly-missing-configuration",
  SCANIFLY_INVALID_CHECK_CODE: "scanifly-invalid-check-code",
  SCANIFLY_START_REQUEST: "scanifly-start-request",
  SCANIFLY_NO_PROJECTS_FOUND: "scanifly-no-projects-found",
  SCANIFLY_PROJECTS_FOUND: "scanifly-projects-found",
  SCANIFLY_PROJECTS_TO_IMPORT: "scanifly-projects-to-import",
  SCANIFLY_PROJECT_SKIPPED: "scanifly-project-skipped",
  SCANIFLY_PROJECT_UPDATED: "scanifly-project-updated",
  SCANIFLY_PROJECT_UPDATE_FAILED: "scanifly-project-update-failed",
  SCANIFLY_PROJECT_CREATED: "scanifly-project-created",
  SCANIFLY_PROJECT_CREATE_FAILED: "scanifly-project-create-failed",
  SCANIFLY_DESIGN_NOT_FOUND: "scanifly-design-not-found",
  SCANIFLY_FILES_TO_IMPORT: "scanifly-files-to-import",
  SCANIFLY_FILE_IMPORTED: "scanifly-file-imported",
  SCANIFLY_FILE_IMPORT_FAILED: "scanifly-file-import-failed",
  SCANIFLY_MEDIA_IMPORTED: "scanifly-media-imported",
  SCANIFLY_MEDIA_IMPORT_FAILED: "scanifly-media-import-failed",
  SCANIFLY_MEDIA_NOT_FOUND: "scanifly-media-not-found",
  SCANIFLY_PROJECT_FILES_UPDATED: "scanifly-project-files-updated",
  SCANIFLY_PROJECT_FILES_UPDATE_FAILED: "scanifly-project-files-update-failed",
  SCANIFLY_IMPORT_ERROR: "scanifly-import-error",
  SCANIFLY_IMPORT_COMPLETE: "scanifly-import-complete",
  SCANIFLY_IMPORT_FAILED: "scanifly-import-failed",

  // Events for tracking KV cache usage
  KV_GET: "kv-get",
  KV_GET_FAILED: "kv-get-failed",
  KV_SET: "kv-set",
  KV_SET_FAILED: "kv-set-failed",

  AMPLIFY_API_QUERY_UNAUTHORIZED: "amplify-api-query-unauthorized",
  AMPLIFY_API_QUERY: "amplify-api-query",
  AMPLIFY_API_QUERY_FAILED: "amplify-api-query-failed",

  // Google Location Picker
  GOOGLE_LOCATION_PICKER_ERROR: "google-location-picker-error",
};

type FriendlyEvent = {
  title: string | ((d: Record<string, unknown>) => string);
  description: string;
  key: string;
  badge?: BadgeVariant;
};

export const friendlyEvents: Record<string, FriendlyEvent> = {
  "error-handler-top-level": {
    title: "Top-Level Error",
    description: "An unexpected error occurred. Our team has been notified.",
    key: "error-handler-top-level",
    badge: "destructive",
  },
  "error-handler-private": {
    title: "Private Module Error",
    description: "An error occurred in a secure area of the application. Our team is on it.",
    key: "error-handler-private",
    badge: "destructive",
  },
  "error-handler-public": {
    title: "Public Module Error",
    description: "An error occurred. We're working to fix it as quickly as possible.",
    key: "error-handler-public",
    badge: "destructive",
  },
  "update-user": {
    title: "User Updated",
    description: "User was successfully updated.",
    key: "update-user",
    badge: "outline",
  },
  "update-user-failed": {
    title: "Update Failed",
    description: "User profile update failed.",
    key: "update-user-failed",
    badge: "destructive",
  },
  "invite-user": {
    title: "User Invited",
    description: "A user was invited. An invitation email has been sent.",
    key: "invite-user",
    badge: "outline",
  },
  "invite-user-failed": {
    title: "Invitation Failed",
    description: "We couldn't send the invitation.",
    key: "invite-user-failed",
    badge: "destructive",
  },
  "identify-user": {
    title: "User Identified",
    description: "Your identity has been confirmed. Welcome!",
    key: "identify-user",
    badge: "outline",
  },
  "identify-guest-user": {
    title: "Guest User Identified",
    description: "Your identity has been confirmed. Welcome!",
    key: "identify-guest-user",
    badge: "outline",
  },
  "guest-username-updated": {
    title: "Guest Username Updated",
    description: "Your guest username has been successfully updated.",
    key: "guest-username-updated",
    badge: "outline",
  },
  "guest-username-update-failed": {
    title: "Guest Username Update Failed",
    description: "We couldn't update your guest username.",
    key: "guest-username-update-failed",
    badge: "destructive",
  },
  "project-created": {
    title: "Project Created",
    description: "A new project has been successfully created.",
    key: "project-created",
    badge: "outline",
  },
  "project-update": {
    title: "Project Updated",
    description: "Your project has been successfully updated.",
    key: "project-update",
    badge: "outline",
  },
  "project-update-failed": {
    title: "Project Update Failed",
    description: "We couldn't update your project. Please try again.",
    key: "project-update-failed",
    badge: "destructive",
  },
  "project-creation-failed": {
    title: "Project Creation Failed",
    description: "Project creation failed.",
    key: "project-creation-failed",
    badge: "destructive",
  },
  "organization-created": {
    title: "Organization Created",
    description: "A new organization has been successfully established.",
    key: "organization-created",
    badge: "outline",
  },
  "organization-creation-failed": {
    title: "Organization Creation Failed",
    description: "Unfortunately, we couldn't establish your new organization.",
    key: "organization-creation-failed",
    badge: "destructive",
  },
  "organization-updated": {
    title: "Organization Updated",
    description: "Your organization's details have been successfully updated.",
    key: "organization-updated",
    badge: "outline",
  },
  "organization-update-failed": {
    title: "Organization Update Failed",
    description: "We couldn't update your organization's details.",
    key: "organization-update-failed",
    badge: "destructive",
  },
  "reset-password": {
    title: "Password Reset",
    description: "Your password has been successfully reset.",
    key: "reset-password",
    badge: "outline",
  },
  "reset-password-failed": {
    title: "Password Reset Failed",
    description: "We couldn't reset your password.",
    key: "reset-password-failed",
    badge: "destructive",
  },
  "reset-password-email-sent": {
    title: "Email Sent",
    description: "We've sent you an email to reset your password.",
    key: "reset-password-email-sent",
    badge: "outline",
  },
  "reset-password-email-failed": {
    title: "Email Failed",
    description: "We couldn't send the reset password email.",
    key: "reset-password-email-failed",
    badge: "destructive",
  },
  "reset-password-email-not-found": {
    title: "Email Not Found",
    description: "The email address you provided is not in our system.",
    key: "reset-password-email-not-found",
    badge: "destructive",
  },
  "project-submitted-for-plans": {
    title: "Project Submitted for Plans",
    description: "This project has been submitted and is now awaiting plan creation.",
    key: "project-submitted-for-plans",
    badge: "default",
  },
  "project-submit-for-plans-failed": {
    title: "Submission Failed",
    description: "We couldn't submit this project for planning.",
    key: "project-submit-for-plans-failed",
    badge: "destructive",
  },
  "project-submitted-for-plans-email-sent": {
    title: "Project Plan Request Email Sent",
    description: "An email has been sent requesting new drawings.",
    key: "project-submitted-for-plans-email-sent",
    badge: "outline",
  },
  "project-submitted-for-plans-email-failed": {
    title: "Project Plan Request Email Failed",
    description: "We couldn't send the confirmation email.",
    key: "project-submitted-for-plans-email-failed",
    badge: "destructive",
  },
  "project-change-request-submitted": {
    title: "Plan Change Request Submitted",
    description: "Your request for changes has been submitted successfully.",
    key: "project-change-request-submitted",
    badge: "default",
  },
  "project-change-request-submit-failed": {
    title: "Plan Change Request Failed",
    description: "We couldn't submit your change request.",
    key: "project-change-request-submit-failed",
    badge: "destructive",
  },
  "project-change-request-email-sent": {
    title: "Plan Change Request Email Sent",
    description: "We've sent a confirmation email regarding your change request.",
    key: "project-change-request-email-sent",
    badge: "outline",
  },
  "project-change-request-email-failed": {
    title: "Plan Change Request Email Failed",
    description: "We couldn't send the confirmation email.",
    key: "project-change-request-email-failed",
    badge: "destructive",
  },
  "project-vendor-change-request-submitted": {
    title: "Project Information Request Submitted",
    description: "A request for additional project information was submitted.",
    key: "project-vendor-change-request-submitted",
    badge: "default",
  },
  "project-vendor-change-request-submit-failed": {
    title: "Project Information Request Failed",
    description: "We couldn't submit your request for additional information.",
    key: "project-vendor-change-request-submit-failed",
    badge: "destructive",
  },
  "project-vendor-change-request-submit-email": {
    title: "Project Information Request Email Sent",
    description:
      "We've sent a confirmation email regarding your request for additional information.",
    key: "project-vendor-change-request-submit-email",
    badge: "outline",
  },
  "project-vendor-change-request-submit-email-failed": {
    title: "Project Information Request Email Failed",
    description:
      "We couldn't send the confirmation email regarding your request for additional information.",
    key: "project-vendor-change-request-submit-email-failed",
    badge: "destructive",
  },
  "project-vendor-plans-submitted": {
    title: "Project Plans Submitted",
    description: "The project plans have been successfully submitted.",
    key: "project-vendor-plans-submitted",
    badge: "default",
  },
  "project-vendor-plans-submit-failed": {
    title: "Project Plans Submission Failed",
    description: "We couldn't submit the project plans.",
    key: "project-vendor-plans-submit-failed",
    badge: "destructive",
  },
  "project-vendor-plans-submit-email": {
    title: "Project Plans Notification Email Sent",
    description: "We've sent a notification email regarding the recently updated project plans.",
    key: "project-vendor-plans-submit-email",
    badge: "default",
  },
  "project-vendor-plans-submit-email-failed": {
    title: "Project Plans Notification Email Failed",
    description:
      "We couldn't send the confirmation email regarding the recently updated project plans.",
    key: "project-vendor-plans-submit-email-failed",
    badge: "destructive",
  },
  "project-plans-approved": {
    title: "Project Plans Approved",
    description: "Your project plans have been approved.",
    key: "project-plans-approved",
    badge: "positive",
  },
  "project-plans-approved-failed": {
    title: "Project Plan Approval Failed",
    description: "We couldn't approve your project plans.",
    key: "project-plans-approved-failed",
    badge: "destructive",
  },
  "project-plans-approved-email-sent": {
    title: "Project Plans Approval Email Sent",
    description: "We've sent a confirmation email regarding your project plans.",
    key: "project-plans-approved-email-sent",
    badge: "outline",
  },
  "project-plans-approved-email-failed": {
    title: "Project Plans Approval Email Failed",
    description: "We couldn't send the confirmation email.",
    key: "project-plans-approved-email-failed",
    badge: "destructive",
  },
  "project-marked-complete": {
    title: "Project Marked Complete",
    description: "Your project has been marked complete.",
    key: "project-marked-complete",
    badge: "positive",
  },
  "project-marked-complete-failed": {
    title: "Project Marked Complete Failed",
    description: "We couldn't mark your project complete.",
    key: "project-marked-complete-failed",
    badge: "destructive",
  },
  "project-marked-complete-email-sent": {
    title: "Project Marked Complete Email Sent",
    description: "We've sent a confirmation email regarding your project.",
    key: "project-marked-complete-email-sent",
    badge: "outline",
  },
  "project-marked-complete-email-failed": {
    title: "Project Marked Complete Email Failed",
    description: "We couldn't send the confirmation email.",
    key: "project-marked-complete-email-failed",
    badge: "destructive",
  },
  "postmark-email-sent": {
    title: "Email Sent",
    description: "Your email has been successfully sent via Postmark.",
    key: "postmark-email-sent",
    badge: "outline",
  },
  "postmark-email-failed": {
    title: "Email Failed",
    description: "We couldn't send your email via Postmark.",
    key: "postmark-email-failed",
    badge: "destructive",
  },
  "project-section-status-updated": {
    title: ({ section }) =>
      section ? `Project Section Complete (${section})` : "Project Section Complete",
    description: "The status of a section in your project has been updated.",
    key: "project-section-status-updated",
    badge: "outline",
  },
  "project-section-status-update-failed": {
    title: "Project Section Update Failed",
    description: "We couldn't update the status of the project section.",
    key: "project-section-status-update-failed",
    badge: "destructive",
  },
  "project-photos-uploaded": {
    title: "Project Photos Uploaded",
    description: "Photos have been successfully uploaded.",
    key: "project-photos-uploaded",
    badge: "outline",
  },
  "project-photos-upload-failed": {
    title: "Project Photos Upload Failed",
    description: "We couldn't upload the photos.",
    key: "project-photos-upload-failed",
    badge: "destructive",
  },
  "message-created": {
    title: "Message Created",
    description: "Your message has been successfully created.",
    key: "message-created",
    badge: "outline",
  },
  "message-creation-failed": {
    title: "Message Creation Failed",
    description: "We couldn't create your message.",
    key: "message-creation-failed",
    badge: "destructive",
  },
  "message-update": {
    title: "Message Updated",
    description: "Your message has been successfully updated.",
    key: "message-update",
    badge: "outline",
  },
  "message-update-failed": {
    title: "Message Update Failed",
    description: "We couldn't update your message.",
    key: "message-update-failed",
    badge: "destructive",
  },
  "message-delete": {
    title: "Message Deleted",
    description: "Your message has been successfully deleted.",
    key: "message-delete",
    badge: "outline",
  },
  "message-delete-failed": {
    title: "Message Delete Failed",
    description: "We couldn't delete your message.",
    key: "message-delete-failed",
    badge: "destructive",
  },
};
